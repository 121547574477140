import { MDXProvider } from '@mdx-js/react'
import React from 'react'

import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'

import { withProps } from './util'

export default ({ children }) => (
  <MDXProvider
    components={{
      h1: withProps(Typography, { variant: 'h5', gutterBottom: true }),
      h2: withProps(Typography, { variant: 'h6', gutterBottom: true }),
      h3: withProps(Typography, {
        variant: 'subtitle1',
        gutterBottom: true,
      }),
      h4: withProps(Typography, {
        variant: 'subtitle1',
        color: 'textSecondary',
        gutterBottom: true,
      }),
      h5: withProps(Typography, {
        variant: 'subtitle2',
        gutterBottom: true,
      }),
      h6: withProps(Typography, {
        variant: 'overline',
        gutterBottom: true,
      }),
      p: withProps(Typography, { style: { marginBottom: '1em' } }),
      li: withProps(Typography, {
        component: 'li',
        style: { display: 'list-item' },
      }),
      a: withProps(Link, { underline: 'always' }),
    }}
  >
    {children}
  </MDXProvider>
)
