import React, { FC } from 'react'
import { RouterProps } from '@reach/router'

import Layout from '../components/layout'
import withRoot from '../withRoot'

import MaterialMDXProvider from '../material-mdx-provider'
import PrivacyMdx from '../privacy.mdx'

const PrivacyPolicyPage: FC<RouterProps> = ({ location: { pathname }}) => (
  <Layout title="Privacy Policy" pathname={pathname}>
    <MaterialMDXProvider>
      <PrivacyMdx />
    </MaterialMDXProvider>
  </Layout>
)

export default withRoot(PrivacyPolicyPage)
